.m-toasts-container {
    position: absolute;
    top: 10px;
    bottom: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 2147483647;
    overflow: auto;
}

.m-toast-message {
    word-break: break-word;
}

.m-toast {
    max-width: 330px;
    border-radius: 5px;
    margin: 0.5rem;
    color: #ffffff;
    font-weight: 900;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: toast-opening 0.25s ease-in-out forwards;
    padding: 15px;
}

@keyframes toast-opening {
    from {
        opacity: 0;
        transform: translateX(100%);
    }

    to {
        opacity: 1;
        transform: translateX(0%);
    }
}

.m-toast>span {
    color: #ffffff;
    margin: 0 15px 0 10px;
    font-size: 1.5rem;
}

.m-toast-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-weight: bold;
    color: #ffffff;
    margin: 0 10px 0 10px;
}


/**************************
       SEVERITY
/**************************/


/* INFO */
.m-toast.info {
    background-color: #337ceb;
    color: #fff
}

/* ERROR */
.m-toast.error {
    background-color: #d9534f;
    color: #fff
}

/* WARNING */
.m-toast.warning {
    background-color: #ffc107;
    color: #fff
}

/* SUCCESS */
.m-toast.success {
    background-color: #67af13;
    color: #fff
}