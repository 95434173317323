.m-scale-container {
    margin: 0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    padding: 5px;
    right: 0;
    font-weight: bold;
    bottom: 2em;
    display: block;
    font-size: smaller;
    background-color: rgba(0, 60, 136, .5);
    font-weight: normal;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    height: 16px !important;
    border: 2px solid white;
    /* vertical-align: middle !important; */
    /*   min-width: 132px;*/
}

.m-area.m-bottom.m-right {
    z-index: 99999 !important;
}

.m-scale-container>.m-unit {
    font-family: Muli, "sans-serif" !important
}

.m-scale-container>.m-unit:before {
    font-family: 'g-cartografia' !important;
    color: #a35d38;
    font-size: 12px !important;
    margin-right: 5px;
}

.m-scale-separator {
    margin-left: 2px;
    margin-right: 2px;
}

/* Firefox fix */

@-moz-document url-prefix() {
    .m-scale-container>.m-scale {
        line-height: 1.3;
    }
}

/* Edge fix */

@supports (-ms-ime-align:auto) {
    div.m-panel-controls>div.m-scale-container>div.m-scale#m-scale-span {
        /* font-family: Arial, Helvetica, sans-serif !important; */
        font-family: Verdana !important;
        font-size: 10.5px;
        line-height: 1.3;
    }

    div.m-panel-controls>div.m-scale-container {
        padding-left: 0 !important;
        white-space: normal !important;
    }

    div.m-panel-controls>div.m-scale-container>.m-unit {
        padding-left: 5px !important;
        white-space: normal !important;
        width: 90px;
    }
}