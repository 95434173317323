.m-control.m-container.m-background {
    background-color: transparent !important;
    border-radius: 0 !important;
}

.m-areas>div.m-area>div.m-panel.no-collapsible>div.m-panel-controls>div.m-control.m-container.m-background>button {
    background-color: #71a7d3 !important;
    border: 2px solid white !important;
    border-radius: 5px;
    color: white;
    align-items: center;
    height: auto;
    font-size: 12px !important;
    padding: 10px !important;
    font-weight: bold !important;
    width: 75px !important;
    font-family: Muli, "sans-serif" !important;
    margin-left: 5px;
}

.m-areas>div.m-area>div.m-panel.no-collapsible>div.m-panel-controls>div.m-control.m-container.m-background>button:first-child {
    margin-left: 0px;
}

.m-background button:active,
.m-background button:focus {
    outline: none;
}

.m-areas>div.m-area>div.m-panel.opened.m-plugin-baselayer {
    background-color: transparent !important;
    box-shadow: none !important;
    order: -10;
}

.m-areas>div.m-area>div.m-panel.no-collapsible>div.m-panel-controls>div.m-control.m-container.m-background>button.m-background-group-btn.activeBaseLayerButton {
    background-color: #92c1e7 !important;
    /* outline: 1px solid orange; */
}

.m-areas>div.m-area>div.m-panel.no-collapsible>div.m-panel-controls>div.m-control.m-container.m-background>button#m-baselayerselector-unique-btn {
    display: none;
}

@media (max-width: 768px) {
    .m-areas>div.m-area>div.m-panel.no-collapsible>div.m-panel-controls>div.m-control.m-container.m-background>button.m-background-group-btn {
        display: none;
    }

    .m-areas>div.m-area>div.m-panel.no-collapsible>div.m-panel-controls>div.m-control.m-container.m-background>button#m-baselayerselector-unique-btn {
        display: block;
    }
}