.m-attributions {
    padding: 0;
    order: 10;
}

.m-panel.opened .m-attributions {
    min-width: 150px;
}

.m-attributions header {
    line-height: 41px;
    background-color: #71a7d3;

}

.attributionElements {
    padding: 20px;
}

.attributionElements:nth-child(even) {
    background-color: #71a7d31f;
}

.attributionElements span {
    font-weight: 800;
    margin-right: 5px;
}

.m-attributions header h1 {
    text-align: center;
    font-size: 13px;
    font-weight: 700;
    margin: 0;
    color: #fff;
}

.m-panel.collapsed.m-panel-attributions>.m-panel-controls>#m-attributions-container {
    display: none;
}

.m-panel.opened.m-panel-attributions>.m-panel-controls>#m-attributions-container {
    display: block;
}

.m-attributions {
    background-color: rgba(0, 0, 0, 0) !important;
    box-shadow: none;
}

.m-areas>div.m-area>div.m-panel.opened.m-attributions {
    background-color: rgba(0, 0, 0, 0) !important;
    box-shadow: none;
}

.m-attributions .m-panel-btn.g-cartografia-flecha-derecha,
.m-attributions .m-panel-btn.g-cartografia-flecha-izquierda {
    background-color: #71a7d3 !important;
    color: white !important;
}

.m-attributions.opened .m-panel-btn.g-cartografia-flecha-derecha,
.m-attributions.opened .m-panel-btn.g-cartografia-flecha-izquierda {
    background-color: white !important;
    color: #71a7d3 !important;
}


.m-container.m-attributions {
    background-color: #fff !important;
    color: #000 !important;
    align-items: center;
    justify-content: center;
}


.m-attributions {
    font-size: 12px;
    color: #000 !important;
    white-space: nowrap;
}

.m-attributions div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.m-attributions #close-button {
    display: none;
}


.m-attributions a,
.m-attributions p {
    font-size: 12px;
    color: #000;
    white-space: nowrap;
    text-decoration: none;
    margin: 0;
    padding: 0;
    max-width: 260px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


@media (max-width: 768px) {
    .m-areas>div.m-area>div.m-panel.no-collapsible.m-panel-attributions.collapsed {
        max-width: 400px;
        margin-bottom: 6vh;
    }

    .m-areas>div.m-area>div.m-panel.m-panel-attributions.opened {
        margin-bottom: 50px;
    }

    .m-areas>div.m-area>div.m-panel.m-panel-attributions>.m-panel-controls>#m-attributions-container {
        /* padding-right: 15%; */
    }

    .m-areas>div.m-area>div.m-panel.m-panel-attributions>.m-panel-controls>#m-attributions-container>div {
        width: calc(100vw - 40px);
    }

    .m-areas>div.m-area>div.m-panel.opened.m-panel-attributions>.m-panel-controls>#m-attributions-container>div {
        height: 24px;
    }
}