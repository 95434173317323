@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-Regular-webfont.eot');
    src: url('../fonts/Muli-Regular-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/Muli-Regular-webfont.woff') format('woff'), url('../fonts/Muli-Regular-webfont.ttf') format('truetype'), url('../fonts/Muli-Regular-webfont.svg#Muli') format('svg');
}

@font-face {
    font-family: 'g-cartografia';
    src: url('../fonts/g-cartografia.eot?49376714');
    src: url('../fonts/g-cartografia.eot?49376714#iefix') format('embedded-opentype'),
        url('../fonts/g-cartografia.ttf?49376714') format('truetype'),
        url('../fonts/g-cartografia.woff?49376714') format('woff'),
        url('../fonts/g-cartografia.svg?49376714#g-cartografia') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="g-cartografia-"],
[class*=" g-cartografia-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'g-cartografia' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.g-cartografia-check:before {
    content: "\e801";
}

.g-cartografia-notification:before {
    content: "\e911";
}

.g-cartografia-warning:before {
    content: "\e910";
}

.g-cartografia-sign:before {
    content: "\e910";
}

.g-cartografia-cancelar:before {
    content: "\e912";
}

.g-cartografia-spinner:before {
    content: "\e90f";
}

.g-cartografia-posicion3:before {
    content: "\e90d";
}

.g-cartografia-brujula-norte:before {
    content: "\e900";
}

.g-cartografia-capas2:before {
    content: "\e901";
}

.g-cartografia-comentarios:before {
    content: "\e902";
}

.g-cartografia-escala3:before {
    content: "\e903";
}

.g-cartografia-flecha:before {
    content: "\e904";
}

.g-cartografia-flecha-abajo:before {
    content: "\e905";
}

.g-cartografia-flecha-arriba:before {
    content: "\e906";
}

.g-cartografia-flecha-derecha:before {
    content: "\e907";
}

.g-cartografia-flecha-izquierda:before {
    content: "\e908";
}

.g-cartografia-gps2:before {
    content: "\e909";
}

.g-cartografia-info:before {
    content: "\e90a";
}

.g-cartografia-papelera:before {
    content: "\e90b";
}

.g-cartografia-pin:before {
    content: "\e90c";
}

.g-cartografia-zoom-extension:before {
    content: "\e90e";
}